<template>
<div>
    <div class="vertical-wrapper pt-lg--7 pt-3">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8 text-center default-page">
                    <div class="card border-0 text-center d-block">
                        <img :src="'/images/coming-soon.png'" alt="icon" class="w300 mb-4 ml-auto mr-auto ">
                        <h1 class="fw-700 text-grey-900 display4-size display4-md-size">Coming Soon.</h1>
                        <!-- <p class="text-grey-500 font-xss">The page you're looking for isn't available. Try to search again or use the go to.</p> -->
                        <button @click="$router.go(-1)" class="p-3 w175 border-0 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3">Return Back</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        name: "404",     
    }
</script>
